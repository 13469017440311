<template>
  <div class="admin-dashboard">
    <div class="progress pa-16" v-if="loading && banners.length">
      <v-data-table
        :headers="headers"
        :items="banners"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:item.src="{ item }">
          <div class="center-all pa-3">
            <v-img
              lazy-src="noImageFound.png"
              :src="item.src"
              max-width="120"
            ></v-img>
          </div>
        </template>
        <template v-slot:item.affiliation="{ item }">
          <div class="center-all">
            <v-chip v-for="(chip, i) in item.affiliation" :key="i">{{
              chip
            }}</v-chip>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </div>
    <my-dialog
      :title="'עריכה'"
      v-model="openEditModel"
      v-if="openEditModel && currentItem"
    >
      <template v-slot:content>
        <v-row v-for="(value, name) in currentItem" :key="name">
          <v-col
            v-if="
              currentItem['loadFor'] !== value &&
              currentItem['affiliation'] !== value
            "
          >
            <v-text-field :label="name" v-model="currentItem[name]" autofocus />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="currentItem['affiliation']"
              :items="chips"
              chips
              small-chips
              label="שיוך"
              multiple
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              label="פלטפורמה"
              v-model="currentItem['loadFor']"
              :items="items"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn text color="primary" v-text="'שמירה'" @click="saveEdit" />
      </template>
    </my-dialog>

    <v-container v-else class="d-flex align-center flex-column"> </v-container>
  </div>
</template>

<script>
import MyDialog from "../components/MyDialog.vue";
export default {
  components: {
    MyDialog,
  },
  name: "Dashboard",
  data() {
    return {
      currentItem: {},
      currentId: "",
      loading: false,
      openEditModel: false,
      items: [
        { value: "mobile", text: "מובייל" },
        { value: "desktop", text: "מחשב" },
      ],
      headers: [
        {
          text: "שם הבאנר",
          align: "center",
          sortable: true,
          value: "bannerName",
        },
        {
          text: "topText",
          align: "center",
          sortable: false,
          value: "topText",
        },
        {
          text: "src",
          align: "center",
          sortable: false,
          value: "src",
        },
        {
          text: "link",
          align: "center",
          sortable: false,
          value: "link",
        },
        {
          text: "שיוך",
          align: "center",
          sortable: false,
          value: "affiliation",
        },
        {
          text: "פלטפורמה",
          align: "center",
          sortable: false,
          value: "loadFor",
        },
        {
          text: "פעולות",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  methods: {
    async deleteItem(item) {
      if (item) {
        await this.$store.dispatch("banners/destroy", item._id);
      }
    },
    editItem(item) {
      const newObj = {
        bannerName: item.bannerName,
        topText: item.topText,
        src: item.src,
        link: item.link,
        loadFor: item.loadFor,
        affiliation: item.affiliation,
      };
      this.currentItem = newObj;
      this.currentId = item._id;
      this.openEditModel = true;
    },
    async saveEdit() {
      try {
        await this.$store.dispatch("banners/update", {
          id: this.currentId,
          payload: this.currentItem,
        });

        this.openEditModel = false;
      } catch (e) {}
    },
  },
  computed: {
    banners() {
      return this.$store.getters.banners;
    },
    chips() {
      if (this.$store.getters.chips.length) {
        return this.$store.getters.chips.map((c) => c.name);
      } else {
        return [];
      }
    },
  },
  async mounted() {
    if (!this.$store.getters.banners.length) {
      await this.$store.dispatch("banners/index");
    }
    await this.$store.dispatch("chips/index");

    this.loading = true;
  },
};
</script>
<style lang="scss" scoped>
.progress {
  width: 100%;
  height: 100%;
  //   margin: 100px auto;
  //   display: flex;
  align-items: center;
  justify-content: center;
}
</style>
