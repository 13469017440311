<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="11">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                icon
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
            <v-col cols="1">
              <v-icon
                color="primary"
                @click="addChipDialog = true"
                v-text="'mdi-plus'"
              />
              <v-icon
                color="primary"
                @click="loadData"
                v-text="'mdi-refresh'"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :loading="loading"
          :items-per-page="50"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <chip-handler
      v-if="addChipDialog"
      v-model="addChipDialog"
      @save="save"
      :isNew="true"
    />
    <chip-handler
      v-if="editDialog"
      v-model="editDialog"
      @save="update"
      :chip="current"
    />
  </div>
</template>

<script>
import ChipHandler from "@/components/ChipHandler.vue";
import SwalMixins from "../mixins/swal";
export default {
  name: "ChipsTable",
  components: { ChipHandler },
  mixins: [SwalMixins],
  data() {
    return {
      loading: false,
      headers: [
        { text: "name", value: "name" },
        { text: "Actions", value: "actions", width: "10%" },
      ],
      current: null,
      search: "",
      addChipDialog: false,
      editDialog: false,
    };
  },
  computed: {
    items() {
      return this.$store.getters.chips;
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("chips/index");
      this.loading = false;
    },
    editItem(item) {
      this.current = item;
      this.editDialog = true;
    },
    async save(item) {
      await this.$store.dispatch("chip/insert", item);
    },
    async update(name) {
      await this.$store.dispatch("chip/update", {
        name,
        _id: this.current._id,
      });
    },
    async deleteItem(item) {
      //TODO: uncomment for confirmation warning
      let { isConfirmed } = await this.createSwal({
        icon: "warning",
        title: `Delete ${item.name}?`,
      });
      if (isConfirmed) {
        await this.$store.dispatch("chip/destroy", item._id);
      }
    },
  },
  async mounted() {
    if (!this.items.length) {
      await this.loadData();
    }
  },
};
</script>