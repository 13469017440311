import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
	rtl: true,
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: "#2196F3",
				secondary: "#424242",
				accent: "#82B1FF",
				error: "#FF5252",
				info: "#2196F3",
				success: "#4CAF50",
				warning: "#FFC107",
			},
		},
	},
});
