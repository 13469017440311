import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Create from "../views/Create.vue";
import Display from "../views/Display.vue";
import Chips from "../views/Chips.vue";
import Adblock from "../views/Adblock.vue";

Vue.use(VueRouter);

const userGuard = async (to, from, next) => {
    try {
        if (store.getters.adminLoggedIn) {
            return to.name === "Login" ? next({ name: "Dashboard" }) : next();
        } else {
            await store.dispatch("auth/checkLogin");
            return to.name === "Login" ? next({ name: "Dashboard" }) : next();
        }
    } catch (e) {
        return to.name === "Login" ? next() : next({ name: "Login" });
    }
};

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        beforeEnter: userGuard,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        beforeEnter: userGuard,
    },
    {
        path: "/create",
        name: "Create",
        component: Create,
        beforeEnter: userGuard,
    },
    {
        path: "/display",
        name: "Display",
        component: Display,
        beforeEnter: userGuard,
    },
    {
        path: "/chips",
        name: "Chips",
        component: Chips,
        beforeEnter: userGuard,
    },
    {
        path: "/adblock",
        name: "Adblock",
        component: Adblock,
        beforeEnter: userGuard,
    },
    {
        path: "*",
        name: "login",
        component: Login,
        beforeEnter: userGuard,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
