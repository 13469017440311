<template>
	<v-app-bar flat>
		<div class="d-flex justify-space-between align-center px-5">
			<v-app-bar-nav-icon color="primary" @click="openDrawer" />
			<v-toolbar-title
				class="primary--text h3 justify-self-start"
				v-text="$store.getters.title"
			/>
		</div>
	</v-app-bar>
</template>

<script>
export default {
	name: "Navbar",
	data() {
		return {
			drawer: false,
		};
	},
	methods: {
		openDrawer() {
			this.$emit("toggleDrawer");
		},
	},
};
</script>
