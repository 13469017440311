import axios from "axios";
export default {
    state: {
        adblock: null,
        adblocks: [],
        "adblocks/filtered": [],
    },
    getters: {
        adblocks: (state) => state.adblocks,
        adblock: (state) => state.adblock,
        "adblocks/filtered": (state) => state["adblocks/filtered"],
    },
    mutations: {
        "adblocks/set": (state, payload) => {
            state.adblocks = payload;
            state.filtered = [...state.adblocks];
        },
        "adblock/set": (state, payload) => (state.adblock = payload),
        "adblocks/filter": (state, { key, val }) => {
            state["adblocks/filtered"] = !val
                ? [...state.adblocks]
                : state["adblocks/filtered"].filter((f) => f[key] === val);
        },
        "adblocks/add": (state, payload) => state.adblocks.push(payload),
        "adblocks/remove": (state, id) =>
            (state.adblocks = state.adblocks.filter((item) => {
                return item._id !== id;
            })),
        "adblocks/update": (state, payload) => {
            state.adblocks = state.adblocks.map((item) => {
                if (item._id === payload._id) {
                    return payload;
                }
                return item;
            });
        },
    },
    actions: {
        "adblocks/index": async (context) => {
            try {
                const { data } = await axios.get("/adblock");
                context.commit("adblocks/set", data);
            } catch (e) {
                console.log(e);
            }
        },
        "adblock/show": async (context, id) => {
            try {
                let { data } = await axios.get("/adblock/" + id);
                context.commit("adblock/set", data);
            } catch (e) {
                console.log(e);
            }
        },
        "adblock/insert": async (context, payload) => {
            try {
                let { data } = await axios.post("/adblock", payload);
                context.commit("adblocks/add", data);
            } catch (e) {
                console.log(e);
            }
        },
        "adblock/remove": async (context, id) => {
            try {
                await axios.delete("/adblock/" + id);
                context.commit("adblocks/remove", id);
            } catch (e) {
                console.log(e);
            }
        },
        "adblock/update": async (context, payload) => {
            try {
                await axios.put("/adblock/" + payload._id, payload);
                context.commit("adblocks/update", payload);
            } catch (e) {
                console.log(e);
            }
        },
    },
};
