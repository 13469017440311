<template>
  <div>
    <my-dialog
      :title="isNew ? 'Add new chip' : `Edit chip`"
      v-model="openModal"
    >
      <template v-slot:content>
        <v-row>
          <v-col>
            <v-text-field
              label="name"
              v-model="name"
              autofocus
              @keydown.enter="submit"
            />
          </v-col>
        </v-row>
        <v-row v-if="err">
          <v-col class="error--text text-center">
            {{ err }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="chip ? 'change' : 'add'"
          @click="submit"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "./MyDialog.vue";
export default {
  name: "chip-handler",
  components: { MyDialog },
  props: { value: Boolean, chip: Object, isNew: Boolean },
  data: () => ({
    name: "",
    err: null,
  }),
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async submit() {
      if (!this.validateForm()) {
        return this.createErr("name is required");
      }
      this.$emit("save", this.name);
      this.openModal = false;
    },
    validateForm() {
      return this.name.length > 0;
    },
    createErr(msg) {
      this.err = msg;
      setTimeout(() => {
        this.err = null;
      }, 2000);
      return;
    },
  },
  mounted() {
    if (!this.isNew) {
      this.name = this.chip.name;
    }
  },
};
</script>