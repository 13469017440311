<template>
  <div class="home">
    <v-container class="form-container d-flex justify-center flex-column mt-12">
      <form @keyup.enter="submit">
        <v-row justify="center">
          <v-col md="8" cols="10">
            <v-text-field
              v-model="email"
              label="אימייל"
              :rules="[rules.emailRequired, rules.validEmail]"
              required
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="8" cols="10">
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              label="סיסמה"
              :type="show ? 'text' : 'password'"
              :rules="[rules.passRequired, rules.min]"
              @click:append="show = !show"
              minLength="6"
              required
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="d-flex justify-center">
            <v-btn
              @click="submit"
              text
              color="primary"
              :disabled="isValidForm"
              v-text="'התחברות'"
            />
          </v-col>
        </v-row>

        <v-row class="justify-center"> </v-row>
      </form>
      <v-alert
        v-if="isAlertMessage"
        outlined
        type="error"
        class="d-flex justify-center mt-5"
        >{{ this.error }}
      </v-alert>
      <div class="progress">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
          v-if="loading"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import GenericMixins from "../mixins/generic";
export default {
  name: "Login",
  mixins: [GenericMixins],
  data: () => {
    return {
      show: false,
      email: "",
      password: "",
      isAlertMessage: false,
      loading: false,
    };
  },
  computed: {
    isValidForm() {
      return !(
        this.email &&
        this.emailValidation(this.email) &&
        this.password &&
        this.password.length >= 6
      );
    },
    rules() {
      return {
        nameRequired: (v) => !!v || "נא להזין שם מלא",
        emailRequired: (v) => !!v || "נא להזין אימייל",
        validEmail: (v) => this.emailValidation(v) || "אימייל לא תקין",
        passRequired: (v) => !!v || "נא להזין סיסמה",
        min: (v) => v.length >= 6 || "6 תווים לפחות",
      };
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
        });
        this.$router.push({ name: "Dashboard" });
      } catch (e) {
        console.log(e);
        console.log("No auth");
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  .progress {
    margin: 20% auto;
  }
}
</style>
