<template>
  <v-navigation-drawer app v-model="openModal" right>
    <v-list nav dense>
      <v-list-item>
        <v-list-item-title class="d-flex justify-space-between mt-3">
          <h3
            class="primary--text font-weight-bold mt-3"
            v-text="'תפריט ראשי'"
          />
        </v-list-item-title>
      </v-list-item>
      <v-divider class="my-2" />
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        @click="goRoute(item.route)"
        :disabled="$route.name === item.route"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="item.title" />
      </v-list-item>
      <v-divider class="my-10" />
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon color="primary">mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="primary--text" v-text="'התנתקות'" />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "Drawer",
  props: { value: Boolean },
  computed: {
    items() {
      return [
        {
          title: "ראשי",
          icon: "mdi-account-multiple",
          route: "Dashboard",
        },
        {
          title: "יצירת באנר",
          icon: "mdi-advertisements",
          route: "Create",
        },
        {
          title: "הצגת באנרים",
          icon: "mdi-account-multiple",
          route: "Display",
        },
        {
          title: "שיוכים",
          icon: "mdi-account-multiple",
          route: "Chips",
        },
        {
          title: "חוסם פרסומות",
          icon: "mdi-advertisements-off",
          route: "Adblock",
        },
      ];
    },
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    goRoute(r) {
      this.$router.push({ name: r });
      this.openModal = false;
    },
    async logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>
