<template>
    <div>
        <div class="center-all py-10">
            <h1>adblock</h1>
        </div>
        <v-container>
            <v-card>
                <v-card-title class="d-flex align-center">
                    <v-row>
                        <v-col cols="11">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                icon
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field
                        ></v-col>
                        <v-col cols="1">
                            <v-icon
                                color="primary"
                                @click="createItem"
                                v-text="'mdi-plus'"
                            />
                            <v-icon
                                color="primary"
                                @click="loadData"
                                v-text="'mdi-refresh'"
                            />
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :search="search"
                    :loading="loading"
                    :items-per-page="50"
                >
                    <template v-slot:item.createdAt="{ item }">
                        {{ formatTime(item.createdAt) }}
                    </template>
                    <template v-slot:item.updatedAt="{ item }">
                        {{ formatTime(item.updatedAt) }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)"
                            >mdi-delete</v-icon
                        >
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
        <adblock-handler
            v-if="openDialog"
            v-model="openDialog"
            @save="save"
            :isNew="isNew"
            :item="current"
        />
    </div>
</template>
<script>
import AdblockHandler from "@/components/AdblockHandler.vue";
import genericMixins from "../mixins/generic";
import swalMixins from "../mixins/swal";

export default {
    name: "adblock",
    components: { AdblockHandler },
    mixins: [swalMixins, genericMixins],
    data() {
        return {
            loading: false,
            headers: [
                { text: "name", value: "name" },
                { text: "createdAt", value: "createdAt" },
                { text: "updatedAt", value: "updatedAt" },
                { text: "Actions", value: "actions", width: "10%" },
            ],
            current: null,
            search: "",
            openDialog: false,
            name: this.$options.name,
        };
    },
    computed: {
        items() {
            return this.$store.getters[this.name + "s"];
        },
    },
    methods: {
        async loadData() {
            this.loading = true;
            await this.$store.dispatch(`${this.name}s/index`);
            this.loading = false;
        },
        editItem(item) {
            console.log(item);
            this.current = item;
            this.isNew = false;
            this.openDialog = true;
        },
        createItem() {
            this.current = null;
            this.isNew = true;
            this.openDialog = true;
        },
        async save(item) {
            console.log(item);
            const action = this.isNew ? "insert" : "update";
            await this.$store.dispatch(`${this.name}/${action}`, item);
        },
        async deleteItem(item) {
            //TODO: uncomment for confirmation warning
            let { isConfirmed } = await this.createSwal({
                icon: "warning",
                title: `Delete ${item.name}?`,
            });
            if (isConfirmed) {
                await this.$store.dispatch(`${this.name}/remove`, item._id);
            }
        },
    },
    async mounted() {
        if (!this.items.length) {
            await this.loadData();
        }
    },
};
</script>
<style lang="scss" scoped></style>
