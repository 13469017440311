<template>
    <div>
        <my-dialog
            :title="isNew ? 'Add new adblock' : `Edit adblock`"
            v-model="openModal"
        >
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="name"
                            v-model="form.name"
                            autofocus
                            @keydown.enter="submit"
                        />
                    </v-col>
                </v-row>
                <v-row v-if="err">
                    <v-col class="error--text text-center">
                        {{ err }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:actions>
                <v-btn
                    text
                    color="primary"
                    v-text="isNew ? 'add' : 'change'"
                    @click="submit"
                />
            </template>
        </my-dialog>
    </div>
</template>
<script>
import MyDialog from "./MyDialog.vue";
export default {
    name: "AdblockHandler",
    components: { MyDialog },
    props: { value: Boolean, item: Object, isNew: Boolean },
    data: () => ({
        form: {
            name: "",
        },
        err: null,
    }),
    computed: {
        openModal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    methods: {
        async submit() {
            if (!this.validateForm()) {
                return this.createErr("name is required");
            }
            this.$emit("save", this.form);
            this.openModal = false;
        },
        validateForm() {
            return this.form.name.length > 0;
        },
        createErr(msg) {
            this.err = msg;
            setTimeout(() => {
                this.err = null;
            }, 2000);
            return;
        },
    },
    mounted() {
        if (!this.isNew) {
            this.form = { ...this.item };
        }
    },
};
</script>
