import axios from "axios";
import router from "../../router/index";
import swalMixins from "../../mixins/swal";
import genericMixins from "../../mixins/generic";

export default {
    state: {
        isLoggedIn: false,
        name: null,
        role: null,
    },
    getters: {
        name: (state) => state.name,
        isAuth: (state) => state.isLoggedIn,
        role: (state) => state.role,
    },
    mutations: {
        setName: (state, payload) => (state.name = payload),
        "auth/login": (state, user) => {
            state.isAuth = true;
            state.name = user.name;
            state.role = user.role;
        },
        clearData: (state) => {
            state.isLoggedIn = false;
            state.name = null;
            state.role = null;
        },
    },
    actions: {
        "auth/register": async (context, payload) => {
            try {
                let { data } = await axios.post("/auth/register", payload);
                context.commit("auth/login", data);
            } catch (e) {
                swalMixins.methods.timeoutSwal({
                    icon: "error",
                    title: genericMixins.methods.extractClapErrorHe(e),
                });
                throw new Error(e.response.data);
            }
        },
        "auth/login": async (context, payload) => {
            try {
                let { data } = await axios.post("/auth/login", payload);
                context.commit("auth/login", data);
            } catch (e) {
                swalMixins.methods.timeoutSwal({
                    icon: "error",
                    title: genericMixins.methods.extractClapErrorHe(e),
                });
                throw new Error(e.response.data);
            }
        },
        "auth/checkLogin": async (context) => {
            try {
                let { data } = await axios.get("/auth/checkLogin");
                context.commit("admin/login", data);
            } catch (e) {
                throw new Error();
            }
        },
        "auth/logout": async (context) => {
            context.commit("clearData");
            await axios.post("/auth/logout");
            router.push({ name: "Login" });
        },
    },
};
