const Swal = require("sweetalert2");
const swalMixins = {
	methods: {
		createSwal({ icon, title, color, text, closeable, lang = "he" }) {
			if (!color) color = "#2196F3";
			if (closeable === null || closeable === undefined) closeable = true;
			let direction = lang === "he" ? "rtl" : "ltr";
			return Swal.fire({
				icon,
				title: `<p style="direction: ${direction} !important;">${title}</p>`,
				html: text
					? `<p style="direction: ${direction} !important;">${text}</p>`
					: "",
				confirmButtonText: lang === "he" ? "אישור" : "Ok",
				confirmButtonColor: color,
				showCancelButton: true,
				cancelButtonText: lang === "he" ? "ביטול" : "Cancel",
				titleColor: "ee44aa",
				allowOutsideClick: closeable,
			});
		},

		timeoutSwal({ icon, title, color, text = "", lang = "he" }) {
			if (!color) color = "#2196F3";
			let direction = lang === "he" ? "rtl" : "ltr";
			return Swal.fire({
				icon,
				title: `<p style="direction: ${direction} !important;">${title}</p>`,
				html: text
					? `<p style="direction: ${direction} !important;">${text}</p>`
					: "",
				showCancelButton: false,
				showConfirmButton: false,
				timer: 1500,
				titleColor: "ee44aa",
				allowOutsideClick: false,
				position: "center",
			});
		},

		createInputSwaldata({
			title,
			inputType = "text",
			color = "#2196F3",
			presistant = false,
			timer = null,
		}) {
			let data = {
				title,
				input: inputType,
				showCancelButton: true,
				confirmButtonColor: color,
				inputValidator: value => {
					if (!value) return "Try again";
				},
				showConfirmButton: true,
				confirmButtonText: "Verify",
				allowOutsideClick: presistant,
				reverseButtons: true,
			};
			if (timer) {
				data["timer"] = timer;
				data["timerProgressBar"] = true;
			}
			return data;
		},
	},
};

export default swalMixins;
