import { render, staticRenderFns } from "./Display.vue?vue&type=template&id=337b840f&"
import script from "./Display.vue?vue&type=script&lang=js&"
export * from "./Display.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCheckbox,VChip,VCol,VContainer,VImg,VRow,VSelect,VSimpleTable,VTextField})
