<template>
  <v-container class="mt-16">
    <v-form ref="form" @submit.prevent="submit" v-model="valid" lazy-validation>
      <v-text-field
        v-model="bannerName"
        label="שם הבאנר"
        required
        :rules="rules.bannerName"
      ></v-text-field>
      <v-text-field
        v-model="src"
        label="src"
        required
        :rules="rules.src"
      ></v-text-field>
      <v-text-field
        v-model="link"
        label="link"
        required
        :rules="rules.link"
      ></v-text-field>
      <v-text-field
        v-model="topText"
        label="topText"
        required
        :rules="rules.topText"
      ></v-text-field>
      <v-text-field
        v-model="affiliation"
        label="צור שיווך"
        append-icon="mdi-content-save"
        @click:append="saveChip"
        @keydown.enter="saveChip"
      ></v-text-field>
      <v-autocomplete
        v-model="affiliationList"
        :items="chips"
        chips
        small-chips
        label="שיוך"
        multiple
      />

      <v-select
        v-model="loadFor"
        :items="items"
        label="loadFor"
        required
        :rules="rules.loadFor"
      ></v-select>
      <v-card-actions class="justify-center">
        <v-btn color="primary" @click="save" :disabled="!formIsValid">
          שמור
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    bannerName: "",
    src: "",
    link: "",
    topText: "",
    affiliation: "",
    affiliationList: [],
    loadFor: null,
    items: [
      { value: "mobile", text: "מובייל" },
      { value: "desktop", text: "מחשב" },
    ],
    rules: {
      bannerName: [(val) => (val || "").length > 0 || "This field is required"],
      src: [(val) => (val || "").length > 0 || "This field is required"],
      link: [(val) => (val || "").length > 0 || "This field is required"],
      topText: [(val) => (val || "").length > 0 || "This field is required"],
      loadFor: [(val) => (val || "").length > 0 || "This field is required"],
    },
  }),
  computed: {
    formIsValid() {
      return (
        this.bannerName && this.src && this.link && this.loadFor && this.topText
      );
    },
    chips() {
      if (this.$store.getters.chips.length) {
        return this.$store.getters.chips.map((c) => c.name);
      } else {
        return [];
      }
    },
  },
  methods: {
    resetForm() {
      this.form = Object.assign({}, this.defaultForm);
      this.$refs.form.reset();
    },
    async save() {
      const b = this.bannerName;
      const s = this.src;
      const l = this.link;
      const t = this.topText;
      const lf = this.loadFor;
      if ((b, s, l, t, lf)) {
        try {
          await this.$store.dispatch("banner/store", {
            bannerName: b,
            src: s,
            link: l,
            topText: t,
            loadFor: lf,
            affiliation: this.affiliationList,
          });
          this.resetForm();
        } catch (e) {
          console.log("error");
        }
      }
    },
    async saveChip() {
      try {
        await this.$store.dispatch("chip/insert", this.affiliation);
        this.affiliationList.push(this.affiliation);
        this.affiliation = "";
      } catch (e) {
        console.log("error");
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("chips/index");
  },
};
</script>
