import axios from "axios";
export default {
    state: { chips: [], "chips/filtered": [], chip: null },
    getters: {
        chips: (state) => state.chips,
        chip: (state) => state.chip,
        "chips/filtered": (state) => state["chips/filtered"],
    },
    mutations: {
        "chips/set": (state, payload) => {
            state.chips = payload;
            state["chips/filtered"] = [...state.chips];
        },
        "chip/set": (state, payload) => (state.chip = payload),
        "chips/filter": (state, { key, val }) => {
            state["chips/filtered"] = !val
                ? [...state.chips]
                : state.chips.filter((f) => f[key].includes(val));
        },
        "chips/add": (state, payload) => state.chips.push(payload),
        "chips/destroy": (state, id) =>
            (state.chips = state.chips.filter((item) => {
                return item._id !== id;
            })),
        "chips/update": (state, payload) => {
            state.chips = state.chips.map((item) => {
                if (item._id === payload._id) {
                    return payload;
                }
                return item;
            });
        },
    },
    actions: {
        "chips/index": async (context) => {
            try {
                const { data } = await axios.get("/chip");
                context.commit("chips/set", data);
            } catch (e) {
                console.log(e);
            }
        },
        "chip/show": async (context, chipName) => {
            try {
                let { data } = await axios.get("/chip/" + chipName);
                context.commit("chips/set", data);
            } catch (e) {
                console.log(e);
            }
        },
        "chip/insert": async (context, name) => {
            try {
                let { data } = await axios.post("/chip", { name });
                context.commit("chips/add", data);
            } catch (e) {
                console.log(e);
            }
        },
        "chip/destroy": async (context, id) => {
            try {
                await axios.delete("/chip/" + id);
                context.commit("chips/destroy", id);
            } catch (e) {
                console.log(e);
            }
        },
        "chip/update": async (context, payload) => {
            try {
                await axios.put("/chip/" + payload._id, payload);
                context.commit("chips/update", payload);
            } catch (e) {
                console.log(e);
            }
        },
    },
};
