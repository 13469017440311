import axios from "axios";
import router from "../../router/index";
import swalMixins from "../../mixins/swal";
import genericMixins from "../../mixins/generic";

export default {
    state: {
        banner: null,
        random: null,
        banners: [],
    },
    getters: {
        banner: (state) => state.banner,
        random: (state) => state.random,
        banners: (state) => state.banners,
    },
    mutations: {
        "banner/set": (state, payload) => (state.banner = payload),
        "banners/set": (state, payload) => (state.banners = payload),
        "banner/random": (state, payload) => (state.random = payload),
        setBannerAndBanner: (state) => {
            state.bannerAndBanner = [];
            state.bannerAndBanner.push(state.random, state.banner);
        },
        removeBannerAndBanner: (state) => {
            state.bannerAndBanner = [];
        },
        "banner/add": (state, payload) => state.banners.push(payload),
        "banner/update": (state, { id, payload }) => {
            state.banners = state.banners.map((item) => {
                if (item._id === id) {
                    return { ...item, ...payload };
                }
                return item;
            });
        },
        "banners/remove": (state, payload) =>
            (state.banners = state.banners.filter((b) => b._id !== payload)),
        "random/remove": (state) => (state.banner = null),
        "banner/remove": (state) => (state.random = null),
    },
    actions: {
        "banner/store": async (context, payload) => {
            try {
                let { data } = await axios.post("/banners", payload);
                context.commit("banner/add", data);
                swalMixins.methods.timeoutSwal({
                    icon: "success",
                    title: "הבאנר נשמר בהצחה",
                });
            } catch (e) {
                swalMixins.methods.timeoutSwal({
                    icon: "error",
                    title: genericMixins.methods.extractClapErrorHe(e),
                });
                throw new Error(e.response.data);
            }
        },
        "banners/update": async (context, { id, payload }) => {
            try {
                let { data } = await axios.put("/banners/" + id, payload);
                context.commit("banner/update", { id: id, payload: payload });
            } catch (e) {
                swalMixins.methods.timeoutSwal({
                    icon: "error",
                    title: genericMixins.methods.extractClapErrorHe(e),
                });
                throw new Error(e.response.data);
            }
        },
        "banners/index": async (context) => {
            try {
                let { data } = await axios.get("/banners");
                context.commit("banners/set", data);
            } catch (e) {
                throw new Error();
            }
        },
        "banners/show": async (context, { affiliation, loadFor }) => {
            try {
                let { data } = await axios.get(
                    "/banners/" + affiliation + "/" + loadFor
                );
                context.commit("banner/set", data);
            } catch (e) {
                swalMixins.methods.timeoutSwal({
                    icon: "error",
                    title: "לא נמצא",
                });
                throw new Error();
            }
        },
        "banners/random": async (context, loadFor) => {
            try {
                let { data } = await axios.get("/random/" + loadFor);
                context.commit("banner/random", data);
            } catch (e) {
                throw new Error();
            }
        },

        "banners/destroy": async (context, id) => {
            try {
                await axios.delete("/banners/" + id);
                context.commit("banners/remove", id);
            } catch (e) {
                throw new Error();
            }
        },
    },
};
