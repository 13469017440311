<template>
	<v-app>
		<drawer v-if="isLoginPage" v-model="drawer" />
		<v-main>
			<navbar v-if="isLoginPage" @toggleDrawer="toggleDrawer" />
			<router-view />
		</v-main>
		<close-app-footer v-if="showFooter" />
	</v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import CloseAppFooter from "./components/CloseAppFooter";
import Drawer from "./components/Drawer.vue";

export default {
	name: "App",
	data: () => ({
		drawer: false,
	}),
	components: { Navbar, CloseAppFooter, Drawer },
	computed: {
		showFooter() {
			// return this.$route.path.includes("/admin");
			return true;
		},
		isLoginPage() {
			return this.$route.name !== "Login";
		},
	},
	methods: {
		toggleDrawer() {
			this.drawer = !this.drawer;
		},
	},
};
</script>
