<template>
  <v-container>
    <v-select
      v-model="loadFor"
      :items="items"
      label="loadFor"
      required
    ></v-select>
    <div v-if="loadFor" class="d-flex justify-space-around">
      <v-checkbox v-model="checkboxR" :label="'רנדומלי'"></v-checkbox>
      <v-checkbox v-model="checkboxP" :label="'פרשה'"></v-checkbox>
    </div>
    <v-row v-if="loadFor">
      <v-col class="center-all" v-if="checkboxP || checkboxR">
        <v-btn @click="show" v-text="'הצג באנר'" />
      </v-col>
      <v-col v-if="checkboxP">
        <v-text-field v-model="affiliation" label="שם או ID" />
      </v-col>
    </v-row>
    <div class="random" v-if="banner || random">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-right">bannerName</th>
              <th class="text-right">topText</th>
              <th class="text-right">src</th>
              <th class="text-right">link</th>
              <th class="text-right">affiliation</th>
              <th class="text-right">loadFor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="random">
              <td>{{ random.bannerName }}</td>
              <td>{{ random.topText }}</td>
              <td>
                <v-img
                  lazy-src="noImageFound.png"
                  :src="random.src"
                  max-width="100"
                />
              </td>
              <td>{{ random.link }}</td>
              <td>
                <v-chip v-for="(chip, i) in random.affiliation" :key="i">{{
                  chip
                }}</v-chip>
              </td>
              <td>{{ random.loadFor }}</td>
            </tr>
            <tr v-if="banner">
              <td>{{ banner.bannerName }}</td>
              <td>{{ banner.topText }}</td>
              <td>
                <v-img
                  lazy-src="noImageFound.png"
                  :src="banner.src"
                  max-width="100"
                />
              </td>
              <td>{{ banner.link }}</td>
              <td>
                <v-chip v-for="(chip, i) in banner.affiliation" :key="i">{{
                  chip
                }}</v-chip>
              </td>
              <td>{{ banner.loadFor }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Display",
  data() {
    return {
      checkboxR: false,
      checkboxP: false,
      loadFor: null,
      affiliation: "",
      items: [
        { value: "mobile", text: "מובייל" },
        { value: "desktop", text: "מחשב" },
      ],
    };
  },
  methods: {
    async showRandom() {
      await this.$store.dispatch("banners/random", this.loadFor);
    },
    async parshasHashavua() {
      await this.$store.dispatch("banners/show", {
        affiliation: this.affiliation,
        loadFor: this.loadFor,
      });
    },
    async show() {
      try {
        if (this.checkboxR && this.checkboxP) {
          await this.parshasHashavua();
          await this.showRandom();
        } else if (this.checkboxR) {
          await this.showRandom();
        } else if (this.checkboxP) {
          await this.parshasHashavua();
        }
      } catch (e) {
        console.log(e);
      }
    },
    removeData() {
      this.$store.commit("random/remove");
      this.$store.commit("banner/random");
    },
  },
  computed: {
    random() {
      return this.$store.getters.random;
    },
    banner() {
      return this.$store.getters.banner;
    },
  },
  beforeDestroy() {
    this.removeData();
  },
};
</script>

<style>
</style>