var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-dashboard"},[(_vm.loading && _vm.banners.length)?_c('div',{staticClass:"progress pa-16"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.banners,"items-per-page":10},scopedSlots:_vm._u([{key:"item.src",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"center-all pa-3"},[_c('v-img',{attrs:{"lazy-src":"noImageFound.png","src":item.src,"max-width":"120"}})],1)]}},{key:"item.affiliation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"center-all"},_vm._l((item.affiliation),function(chip,i){return _c('v-chip',{key:i},[_vm._v(_vm._s(chip))])}),1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,false,1362224906)})],1):_vm._e(),(_vm.openEditModel && _vm.currentItem)?_c('my-dialog',{attrs:{"title":'עריכה'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._l((_vm.currentItem),function(value,name){return _c('v-row',{key:name},[(
            _vm.currentItem['loadFor'] !== value &&
            _vm.currentItem['affiliation'] !== value
          )?_c('v-col',[_c('v-text-field',{attrs:{"label":name,"autofocus":""},model:{value:(_vm.currentItem[name]),callback:function ($$v) {_vm.$set(_vm.currentItem, name, $$v)},expression:"currentItem[name]"}})],1):_vm._e()],1)}),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.chips,"chips":"","small-chips":"","label":"שיוך","multiple":""},model:{value:(_vm.currentItem['affiliation']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'affiliation', $$v)},expression:"currentItem['affiliation']"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"פלטפורמה","items":_vm.items},model:{value:(_vm.currentItem['loadFor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'loadFor', $$v)},expression:"currentItem['loadFor']"}})],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"text":"","color":"primary"},domProps:{"textContent":_vm._s('שמירה')},on:{"click":_vm.saveEdit}})]},proxy:true}],null,false,2294835031),model:{value:(_vm.openEditModel),callback:function ($$v) {_vm.openEditModel=$$v},expression:"openEditModel"}}):_c('v-container',{staticClass:"d-flex align-center flex-column"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }